import { useState, useEffect } from 'react'
import Intercom from '@intercom/messenger-js-sdk'
import apiClient from '../axiosConfig/apiClient'
import { CHATBOT_INFO } from '../constants/api'

// eslint-disable-next-line react-func/max-lines-per-function
const Chatbot = () => {
  const [userInfo, setUserInfo] = useState(null)
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const checkMobile = () => {
      if (window.innerWidth <= 1024) {
        setIsMobile(true)
      }
    }

    checkMobile()
    window.addEventListener('resize', checkMobile)

    return () => {
      window.removeEventListener('resize', checkMobile)
    }
  }, [])

  useEffect(() => {
    if (userInfo && !isMobile) {
      Intercom({
        app_id: process.env.REACT_APP_INTERCOM_APP_ID,
        user_id: userInfo.id,
        name: userInfo.name,
        email: userInfo.email
      })
    }
  }, [userInfo, isMobile])

  useEffect(() => {
    const fetchUserInfo = async () => {
      const response = await apiClient.get(CHATBOT_INFO)
      setUserInfo(response.data)
    }

    fetchUserInfo()
  }, [])

  return null
}

export default Chatbot
